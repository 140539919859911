import Cookies from "js-cookie";

export const getLanguage = () => {
  return Cookies.get("lang") || "";
};

export const getToken = () => {
  return Cookies.get("auth_token");
};

export const getIsAuth = () => {
  return Cookies.get("isAuth");
};
export const setToken = (token) => {
  if (typeof window === undefined) {
    return null;
  }
  Cookies.set("auth_token", token);
  Cookies.set("isAuth", 1);
};

export const clearAuth = (token) => {
  if (typeof window === undefined) {
    return null;
  }
  Cookies.remove("auth_token", token);
  Cookies.remove("isAuth", 1);
};

export const setUserCookie = (data) => {
  if (typeof window === undefined) {
    return null;
  }
  Cookies.set("user", JSON.stringify(data));
};

export const getUserCookie = () => {
  return Cookies.get("user");
};

export const getUserCurrency = () => {
  return Cookies.get("currency");
};
