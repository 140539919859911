import { createContext, useContext, useState, useEffect } from "react";
import Cookies from "js-cookie";

const CurrencyContext = createContext();

export const CurrencyProvider = ({ children }) => {
  const [currency, setCurrency] = useState("EUR"); // Default currency

  useEffect(() => {
    const storedCurrency =
      typeof window !== "undefined"
        ? localStorage.getItem("currency")
        : Cookies.get("currency");

    if (storedCurrency) {
      setCurrency(storedCurrency);
    }
  }, []);

  useEffect(() => {
    if (!currency) return;
    
    if (typeof window !== "undefined") {
      // Save the selected currency to localStorage & Cookies
      localStorage.setItem("currency", currency);
    }
    Cookies.set("currency", currency);
  }, [currency]);

  return (
    <CurrencyContext.Provider value={{ currency, setCurrency }}>
      {children}
    </CurrencyContext.Provider>
  );
};

export const useCurrency = () => useContext(CurrencyContext);
