import i18next from "i18next";

import de_common from "./public/locales/de/common.json";
import en_common from "./public/locales/en/common.json";

i18next.init({
  ns: ["common"],
  defaultNS: "common",
  interpolation: { escapeValue: false },
  lng: "de",
  fallbackLng: "de",
  debug: false,
  resources: {
    en: {
      common: en_common,
    },
    de: {
      common: de_common,
    },
  },
  localeSubpaths: {
    en: "en",
    de: "de",
  },
});

export default i18next;
