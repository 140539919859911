import { makeAutoObservable } from 'mobx'

export default class UserModel {
  constructor() {
    makeAutoObservable(this)
  }

  name = ''
  email = ''
  phone = ''
  name = ''
  last_name = ''
  photo = ''
  email = ''
  phone = ''
  date_of_birth = ''
  address = {}
  sport = []
  dietary = []
}
